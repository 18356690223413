import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import { MESSAGE_ALERT_NIMBUS_FREE, productMessageSubscriptionStatusMap } from '../../constants';
import { AccountContext } from '../../views/accountsView/account/AccountContext';
import { getTypeTierMapBySubscription } from '../../helpers/utils/get-variable-content-by-subscription.utils';
import SUBSCRIPTION_TIER_MAP from '../../helpers/mapping/subscription-tier.mapping';

import Styles from './SubscriptionStatusBanner.module.scss';

const SubscriptionStatusBanner = () => {
  const [accountContext] = AccountContext();
  const productData = accountContext.products[accountContext.currentProductId];
  const statusDefault = productData?.status || productMessageSubscriptionStatusMap.NM.status;
  const subscriptionStatusMap = getTypeTierMapBySubscription(productData);
  let returnContent = <div />;

  const MESSAGE = (typeof productData?.blocking_label === 'string' && productData?.blocking_label !== '')
    ? productData?.blocking_label
    : productMessageSubscriptionStatusMap[statusDefault].message;

  if (subscriptionStatusMap === SUBSCRIPTION_TIER_MAP.active.NSS.FR) {
    returnContent = (
      <div className={Styles.alertNimbusFreeBannerContainer}>
        <p className="m-0 py-3 alert alert-info">
          <FontAwesomeIcon
            icon={faInfoCircle}
            className="me-2 text-info"
          />
          <abbr title={MESSAGE_ALERT_NIMBUS_FREE}>
            {MESSAGE_ALERT_NIMBUS_FREE}
          </abbr>
        </p>
      </div>
    );
  }

  if (productData?.status === productMessageSubscriptionStatusMap.NM.status
      || productData?.status === productMessageSubscriptionStatusMap.PB.status
      || productData?.status === undefined) {
    return returnContent;
  }

  return (
    <>
      {returnContent}
      <div className={Styles.alertPaymentBannerContainer}>
        <p
          className={`m-0 py-3 alert ${productMessageSubscriptionStatusMap[statusDefault].style}`}
        >
          <FontAwesomeIcon
            icon={productMessageSubscriptionStatusMap[statusDefault].icon}
            className={productMessageSubscriptionStatusMap[statusDefault].iconStyle}
          />
          <abbr title={MESSAGE}>{MESSAGE}</abbr>
        </p>
      </div>
    </>
  );
};

export default SubscriptionStatusBanner;
